@import '../../node_modules/bootstrap/scss/_functions.scss'
@import '../../node_modules/bootstrap/scss/_variables.scss'

.success
  i
    color: $success !important
.warning
  i
    color: $warning !important
.danger
  i
    color: $danger !important
